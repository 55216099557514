import React, {FunctionComponent, useEffect, useState} from "react";
import './EventItemModal.scss';
import Image from '../../base-components/BaseImage';
import RadioButton from '../../base-components/BaseRadio'
import YesNoModal from '../../../components/modal/modal-yes-no/YesNoModal';
import cancelIcon from '../../../assets/icons/cancel.svg';
import BaseFormInput from "../../base-components/BaseFormInput";
import {publicHolidaysKvP} from "../../../utils/Utils";
import {DateUtils} from '../../../utils/DateUtils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type EventItemProps = {
    image: string,
    alt: string,
    radioId: string,
    label: string,
    budget?: string,
    onDelete?: any,
    onEdit?: any,
    date?: any,
    onRadioBtnClick?: any,
    radioActive?: { activeId: string, setActiveId: any },
    onBudgetEdit?: any
}

//used for interest too
const EventItemModal: FunctionComponent<EventItemProps> = ({
                                                               image,
                                                               alt,
                                                               radioId,
                                                               label,
                                                               budget,
                                                               onDelete,
                                                               onEdit,
                                                               date,
                                                               onRadioBtnClick,
                                                               radioActive,
                                                               onBudgetEdit
                                                           }) => {
    const [editBudget, setEditBudget] = useState(false)
    const [editDate, setEditDate] = useState(false)
    const [occasionBudget, setOccasionBudget] = useState(0)

    const [budgetChanged, setBudgetChanged] = useState(false)
    const [startDate, setStartDate] = useState(date ? new Date(date).toISOString() : new Date().toISOString());

    function setTheBudget(budget, state) {
        setBudgetChanged(state)
        setOccasionBudget(budget)
    }

    let onDateEdit = (date) => {
        setStartDate(date.toISOString());
        onEdit(budget, date.toISOString());
        setEditDate(false);
    }

    function saveBudget(budget) {

        if (budgetChanged) {
            setBudgetChanged(false)

            if (budget >= 0) {
                onEdit(budget, startDate)
            }

            if (onBudgetEdit) {
                onBudgetEdit(budget)
            }
        }
        setEditBudget(false)
    }

    function onBudgetFieldKeyDown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
            saveBudget(occasionBudget)
            return
        }
    }

    function getName(name) {
        return onEdit ? publicHolidaysKvP.find(holiday => holiday.key == label)?.value : name
    }

    function setEditBudgetOrDate(budget) {
        if (budget) {
            setEditDate(false)
            setEditBudget(true)
        } else {
            setEditDate(true)
            setEditBudget(false)
        }
    }

    function dateOnBlur() {
        setEditDate(false)
    }

    return (
        <div className="event-item-container">
            {
                onDelete &&
                <YesNoModal
                    modalTitle={`Remove "${getName(label)}"`}
                    subtitle={`Are you sure you want to delete "${getName(label)}"?`}
                    icon={cancelIcon}
                    onPositive={() => onDelete()}
                />
            }
            <Image img={image}
                   alt={alt}/>
            <RadioButton
                radioActive={radioActive}
                radioId={radioId}
                onClick={onRadioBtnClick}
                label={getName(label)}
            />
            
            {
                label === 'JustBecause' ? null : !editDate && date
                    ?
                    <span style={{zIndex: 1, cursor: "pointer"}}
                          onClick={() => setEditBudgetOrDate(false)}>{DateUtils.utcToLocal(new Date(startDate)).toDateString()}</span>
                    :
                    onEdit && <DatePicker
                        autoFocus
                        defaultValue={DateUtils.utcToLocal(new Date(startDate))}
                        onChange={(d) => onDateEdit(DateUtils.localToUtc(d))}
                        onBlur={() => dateOnBlur()}
                    />
            }

            {/*
                !editDate && date
                    ?
                    <span style={{zIndex: 1, cursor: "pointer"}}
                          onClick={() => setEditBudgetOrDate(false)}>{DateUtils.utcToLocal(new Date(startDate)).toDateString()}</span>
                    :
                    onEdit && <DatePicker
                        autoFocus
                        defaultValue={DateUtils.utcToLocal(new Date(startDate))}
                        onChange={(d) => onDateEdit(DateUtils.localToUtc(d))}
                        onBlur={() => dateOnBlur()}
                    />
            */}

            {
                editBudget
                    ?
                    <BaseFormInput autoFocus onKeyDown={(e) => onBudgetFieldKeyDown(e)} type="number"
                                   onBlur={(e) => saveBudget(e)} onChange={(e) => setTheBudget(e, true)}
                                   placeholder={"$ " + budget}></BaseFormInput>
                    :
                    onEdit ? <span style={{cursor: "pointer"}}
                                   onClick={() => setEditBudgetOrDate(true)}>{"$ " + budget}</span> :
                        <span>{budget}</span> //used for interest too
            }
        </div>
    )
}

export default EventItemModal;

import React, { useEffect, useState } from "react";
import './DatePickerFormComponent.scss';
import { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { getYears, getMonth, monthToDays} from '../../utils/DateUtils';
import { ContactSupportOutlined } from "@material-ui/icons";

type DatePickerProps = {
    value?: Date,
    onChange?: Function,
}

const DatePickerFormComponent: FunctionComponent<DatePickerProps> = (props) => {

    const [yearIsActive, yearSetActive] = useState(false);
    const [monthIsActive, monthSetActive] = useState(false);
    const [dayIsActive, daySetActive] = useState(false);

    let value: Date | undefined;

    if (typeof props.value === 'string') {
        value = new Date(props.value);
    }
    else {
        value = props.value;
    }

    const [year, setYear] = useState(value?.getUTCFullYear());
    const [month, setMonth] = useState(value?.getUTCMonth());
    const [day, setDay] = useState(value?.getUTCDate());

    const monthToggle = () => {
        monthSetActive(!monthIsActive);
    };

    const dayToggle = () => {
        daySetActive(!dayIsActive);
    };

    const yearToggle = () => {
        yearSetActive(!yearIsActive);
    };

    const yearChanged = (e) => {
        setYear(parseInt(e.currentTarget.value));
        recreateValue(e.currentTarget.value, month, day);
    };

    const monthChanged = (e) => {
        setMonth(parseInt(e.currentTarget.value));
        recreateValue(year, e.currentTarget.value, day);
    };

    const dayChanged = (e) => {
        setDay(parseInt(e.currentTarget.value));
        recreateValue(year, month, e.currentTarget.value);
    };

    const recreateValue = (year, month, day) => {
        value = new Date(Date.UTC(year ?? 2200, month ?? 0, day ?? 1))
        if (props.onChange) props.onChange(value);
    };

    return (
        <Col className="pt-3">
            <h5>Birthday</h5>
            <Row>
                <Col sm={6} md={4}>
                    <div className={`select-wapper ${monthIsActive ? "open" : ""}`}>
                        <select className="DatePicker" placeholder="Month"
                            defaultValue={month}
                            onClick={monthToggle}
                            onChange={(e) => monthChanged(e)}>
                            <option hidden value="">Month</option>
                            {getMonth().map((option, index) => (
                                <option key={index} value={index}>{option}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col sm={6} md={4}>
                    <div className={`select-wapper ${dayIsActive ? "open" : ""}`}>
                        <select className="DatePicker" placeholder="Day"
                            defaultValue={day}
                            onClick={dayToggle}
                            onChange={(e) => dayChanged(e)}>

                            <option hidden value="">Day</option>
                            {monthToDays(month, year).map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col sm={6} md={4}>
                    <div className={`select-wapper ${yearIsActive ? "open" : ""}`}>
                        <select className="DatePicker" placeholder="Year"
                            defaultValue={year}
                            onClick={yearToggle}
                            onChange={(e) => yearChanged(e)}>

                            <option hidden value="">Year</option>
                            {getYears().map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

export { DatePickerFormComponent };